<template>
    <div class="contacts">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 150px"
                        placeholder="请输入标签名称"
                        v-model="name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
            </div>
        </div>
        <div class="table-title">
            <div class="status_btn">
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(1)"
                    :class="isStsts == 1 ? 'isStatusColor' : ''"
                    >全部</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(2)"
                    :class="isStsts == 2 ? 'isStatusColor' : ''"
                >
                    个人标签
                </span>
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(3)"
                    :class="isStsts == 3 ? 'isStatusColor' : ''"
                    >系统标签</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(4)"
                    :class="isStsts == 4 ? 'isStatusColor' : ''"
                    >部门标签</span
                >
            </div>
            <span>
                <button @click="onAdd()" v-if="btnP.add">
                    <i class="el-icon-plus"></i> 新增标签
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column
                label="序号"
                prop="indexData"
                show-overflow-tooltip
                width="60"
            >
            </el-table-column>

            <el-table-column
                label="标签名称"
                prop="title"
                show-overflow-tooltip
                width="120"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.tagName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="绑定数量"
                prop="acceptAdminName"
                show-overflow-tooltip
                width="70"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.total }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="标签类型" width="80" show-overflow-tooltip
                ><template slot-scope="scope">
                    <span>{{
                        scope.row.category == 0
                            ? '系统标签'
                            : scope.row.category == 1
                            ? '个人标签'
                            : '部门标签'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="标签备注" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.remark || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="最后修改时间"
                width="160"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.updateTime || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" show-overflow-tooltip width="110">
                <template slot-scope="scope">
                    <span v-if="adminId == scope.row.adminId">
                        <el-button
                            v-if="btnP.edit"
                            type="text"
                            @click="onEdit(scope.row)"
                            style="color: #2370eb"
                        >
                            编辑
                        </el-button>
                        <el-button
                            v-if="btnP.delete"
                            type="text"
                            @click="onDelete(scope.row)"
                            style="color: #d0021b"
                        >
                            删除
                        </el-button></span
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 添加标签 -->
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增标签
                </div>
            </template>
            <AddLable ref="addLable" @close="handleAddlabel" />
        </el-dialog>
        <!-- 编辑标签 -->
        <el-dialog
            :visible.sync="dialogEdit"
            width="560px"
            :before-close="handleEdit"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增标签
                </div>
            </template>
            <EditLable ref="editLable" @close="handleEdit" />
        </el-dialog>
        <!-- 删除弹窗 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除
                </div>
            </template>

            <Delete ref="delete" @close="handleClose" />
        </el-dialog>
    </div>
</template>
<script>
import { manageList } from '@/api/user/label.js';
import AddLable from './add.vue';
import EditLable from './edit.vue';
import Delete from './delete.vue';
export default {
    components: {
        AddLable,
        EditLable,
        Delete,
    },
    data() {
        return {
            name: '',
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            dialogAddlabel: false,
            dialogEdit: false,
            dialogText: '',
            dialog: false,
            btnP: {},
            isStsts: 1,
            adminId: sessionStorage.getItem('adminId'),
            route: '',
        };
    },
    mounted() {
        this.getBtn();
        this.route = this.$route.path;
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            this.getData(this.isStsts);
        },
        getData(i) {
            if (i) {
                this.isStsts = i;
            }
            let data = {
                param: {
                    name: this.name,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.$route.path == '/custLabel') {
                data.param.type = 2;
            }
            if (this.$route.path == '/baseLabel') {
                data.param.type = 6;
            }
            if (i == 1) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (i == 2) {
                data.param.category = 1;
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (i == 3) {
                data.param.category = 0;
            }
            if (i == 4) {
                data.param.category = 2;
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            manageList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                console.log(this.pagesize, this.currentPage);
                let Nub = 0;
                if (this.currentPage == 1) {
                    for (let i in this.tableData) {
                        this.tableData[i].indexData = Number(i) + 1;
                    }
                } else if (this.currentPage == 2) {
                    for (let i in this.tableData) {
                        this.tableData[i].indexData =
                            Number(this.pagesize) + Number(i) + 1;
                    }
                } else {
                    console.log(Nub);
                    for (let i in this.tableData) {
                        if (this.pagesize > 10) {
                            this.tableData[i].indexData =
                                Number(this.pagesize * this.currentPage) -
                                this.pagesize +
                                Number(i) +
                                1;
                        } else {
                            this.tableData[i].indexData = Number(
                                this.pagesize * this.currentPage
                            );
                            Number(i) + 1;
                        }
                    }
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData(this.isStsts);
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData(this.isStsts);
        },
        onAdd() {
            this.dialogAddlabel = true;
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = [];
            for (let i in btnArr) {
                if (btnArr[i].route == 'add') {
                    btn = btnArr[i].children;
                }
            }

            setTimeout(() => {
                this.$refs.addLable.getBut(btn);
            });
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.getData(this.isStsts);
        },
        onEdit(row) {
            this.dialogEdit = true;
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = [];
            for (let i in btnArr) {
                if (btnArr[i].route == 'edit') {
                    btn = btnArr[i].children;
                }
            }
            setTimeout(() => {
                this.$refs.editLable.getData(row, btn);
            });
        },
        handleEdit() {
            this.dialogEdit = false;
            this.getData(this.isStsts);
        },
        onDelete(row) {
            this.dialog = true;
            setTimeout(() => {
                this.$refs.delete.getData(row);
            });
        },
        handleClose() {
            this.dialog = false;
            this.getData(this.isStsts);
        },
        searchEmpty() {
            this.searchVal = {};
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contacts {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
        .cancel:hover {
            background: #fff;
            color: #666;
            border-color: #e5e5e5;
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.status_btn {
    span {
        display: inline-block;
        border: 1px solid #f0f0f0;
        border-radius: 13px;
        padding: 4px 12px;
        font-size: 13px;
        color: #666666;
        margin-right: 12px;
        cursor: pointer;
        position: relative;
    }
    .isStatusColor {
        background: #e9f2ff;
        color: #2370eb;
        border-color: #e9f2ff;
    }
    .toast_gif {
        position: absolute;
        font-size: 12px;
        width: 16px;
        height: 16px;
        border-radius: 100px;
        color: #fff;
        text-align: center;
        line-height: 16px;
        font-weight: 560;
        right: -5px;
        top: -5px;
    }
}
</style>
