<template>
    <div>
        <div class="add-form">
            <el-form ref="form" :model="form" label-width="160px">
                <el-form-item label="标签名称" required>
                    <el-input
                        style="width: 270px"
                        v-model="form.tagName"
                        placeholder="请输入标签名称"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="标签类型" required>
                    <el-radio v-model="radio" :label="1" v-if="btnP.user">个人标签</el-radio>
                    <el-radio v-model="radio" :label="0" v-if="btnP.system">系统标签</el-radio>
                    <el-radio v-model="radio" :label="2" v-if="btnP.demp">部门标签</el-radio>
                </el-form-item>
                <el-form-item label="标签备注">
                    <el-input
                        type="textarea"
                        :rows="4"
                        style="width: 270px"
                        v-model="form.remark"
                        placeholder="请输入标签备注"
                        size="small"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: auto"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff; margin-right: 30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { addLabel } from '@/api/user/label';
export default {
    data() {
        return {
            form: {
                tagName: '',
                remark: '',
            },
            radio: 1,
            btnP: {},
        };
    },
    methods: {
        getBut(btnArr) {
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            console.log(this.btnP);
        },
        onSubmit() {
            if (this.form.tagName == '') {
                this.$message.error('请输入标签名称');
            } else {
                let data = {
                    param: this.form,
                };
                if (this.$route.path == '/custLabel') {
                    data.param.type = 2;
                }
                if (this.$route.path == '/baseLabel') {
                    data.param.type = 6;
                }
                data.param.category = this.radio;
                addLabel(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                    }
                    this.form.remark = '';
                    this.form.tagName = '';
                    this.radio = 1;
                    this.$emit('close');
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add-form {
    padding: 32px 0 100px 0;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
